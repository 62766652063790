export default [
  {
    categoryName: "Dashboard",
    items: [
      {
        itemName: "Dashboard",
        itemLink: "/admin/dashboard",
      },
    ],
  },
  {
    categoryName: "Statistics",
    items: [
      {
        itemName: "Charts",
        itemLink: "/admin/statistics/charts",
      },
      {
        itemName: "Progress bars",
        itemLink: "/admin/statistics/progress-bars",
      },
    ],
  },
  {
    categoryName: "Forms",
    items: [
      {
        itemName: "Form Elements",
        itemLink: "/admin/forms/form-elements",
      },
      {
        itemName: "Medium Editor",
        itemLink: "/admin/forms/medium-editor",
      },
    ],
  },
  {
    categoryName: "Tables",
    items: [
      {
        itemName: "Markup Tables",
        itemLink: "/admin/tables/markup",
      },
      {
        itemName: "Data Tables",
        itemLink: "/admin/tables/data",
      },
    ],
  },
  {
    categoryName: "UI Elements",
    items: [
      {
        itemName: "Typography",
        itemLink: "/admin/ui/typography",
      },
      {
        itemName: "Buttons",
        itemLink: "/admin/ui/buttons",
      },
      {
        itemName: "Cards",
        itemLink: "/admin/ui/cards",
      },
      {
        itemName: "Chat",
        itemLink: "/admin/ui/chat",
      },
      {
        itemName: "Chips",
        itemLink: "/admin/ui/chips",
      },
      {
        itemName: "Collapses",
        itemLink: "/admin/ui/collapses",
      },
      {
        itemName: "Colors",
        itemLink: "/admin/ui/colors",
      },
      {
        itemName: "Color Pickers",
        itemLink: "/admin/ui/color-pickers",
      },
      {
        itemName: "Icons",
        itemLink: "/admin/ui/icons",
      },
      {
        itemName: "Lists",
        itemLink: "/admin/ui/lists",
      },
      {
        itemName: "Modals",
        itemLink: "/admin/ui/modals",
      },
      {
        itemName: "Notifications",
        itemLink: "/admin/ui/notifications",
      },
      {
        itemName: "Popovers",
        itemLink: "/admin/ui/popovers",
      },
      {
        itemName: "Rating",
        itemLink: "/admin/ui/rating",
      },
      {
        itemName: "Spinners",
        itemLink: "/admin/ui/spinners",
      },
      {
        itemName: "Tabs",
        itemLink: "/admin/ui/tabs",
      },
      {
        itemName: "Timelines",
        itemLink: "/admin/ui/timelines",
      },
      {
        itemName: "Tree View",
        itemLink: "/admin/ui/tree-view",
      },
    ],
  },
  {
    categoryName: "Maps",
    items: [
      {
        itemName: "Google Maps",
        itemLink: "/admin/maps/google-maps",
      },
      {
        itemName: "Yandex Maps",
        itemLink: "/admin/maps/yandex-maps",
      },
      {
        itemName: "Leaflet Maps",
        itemLink: "/admin/maps/leaflet-maps",
      },
      {
        itemName: "Bubble Maps",
        itemLink: "/admin/maps/bubble-maps",
      },
      {
        itemName: "Line Maps",
        itemLink: "/admin/maps/line-maps",
      },
    ],
  },
];
